import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Heading, Paragraph } from "grommet"
import { MiBusLogo } from "./miBusLogo"

export const StyledHeading = styled(Heading)`
  font-weight: 200;
`;

const MiBus = ({ size, ...rest }) => (
  <Fragment>
    <MiBusLogo size={size} {...rest}/>
    <Paragraph margin={{ top: "none" }} textAlign="center" size="xxlarge">
      универсално устройство за мониторинг и управление на индустриални машини с RS485 комуникация.
      използвайте mi<b>Bus</b> за да следите и контролирате всички ваши устройства от едно място!
    </Paragraph>
  </Fragment>
);

MiBus.propTypes = {
  size: PropTypes.string.isRequired
};

export { MiBus };