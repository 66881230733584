import React from "react";
import { Box, Heading, Text } from "grommet"
import { StyledHeading } from "./miBus"

export const MiBusLogo = ({size, beta = true, ...rest}) => (
  <Box direction="row" {...rest}>
    <StyledHeading size={size}>mi</StyledHeading>
    <Heading size={size}>Bus</Heading>
    {beta && <Text margin={{top: 'medium'}} size='medium' color='status-error'>beta</Text>}
  </Box>
);